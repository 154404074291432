import React from "react";

function Blog() {
  return (
    <div className="h-screen w-full flex justify-center items-center text-white text-4xl px-20">
      Come back soon will be diving into the life of Zakaria Rab and how to get
      a internship at a FAANG+ company!
    </div>
  );
}

export default Blog;
